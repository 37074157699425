import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from '../../Config/axios';
import useAuth from '../../Hooks/useAuth';
import Swal from 'sweetalert2'
import { deleteAndConfirmation } from '../../Utils/confirmation';


const Appointments = () => {

    const [appointments, setAppointments] = useState([])
    const [filterDate, setFilterDate] = useState('')

    const { auth } = useAuth()

    const navigate = useNavigate()

    console.log('====================================');
    console.log(auth);
    console.log('====================================');

    const fetchAppointments = async () => {

        try {
            const response = await axios.get('/v1/appointments/', {
                headers: {
                    authorization: `Bearer ${auth.accessToken}` 
                }
            })

            setAppointments(response?.data)

            console.log(response?.data);
        } catch (error) {
            console.error(error);
        }
    }

    const deleteRecord = async (id) => {
        try {
            deleteAndConfirmation(`/v1/appointments/${id}`, auth.accessToken, 1000, "The appointment has been deleted.")
        } catch (error) {
            console.error(error);
        }
    }

    const filterByDate = (date) => {
        setFilterDate(date)
    }

    useEffect(() => {

        // if (!filterDate) {
        //     const date = new Date()

        //     setFilterDate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
        // }
        fetchAppointments()
    }, [filterDate])


  return (
    <div className='pl-20 pt-10'>
        <div class="flex space-x-80">
            <Link to="/dashboard/appointments/new">
                <Button variant="contained">Add new Appointment</Button>
            </Link>

            <div>
            <input 
                onChange={e => filterByDate(e.target.value)} 
                type="date" 
                name="date"
                value={filterDate}
                // defaultValue={new Date()}
                id="floating_repeat_password" 
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" " 
                required />
            </div>

        </div>
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            Users
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Date
                        </th>
                        <th scope="col" class="px-6 py-3">
                            From
                        </th>
                        <th scope="col" class="px-6 py-3">
                            To
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Actions
                        </th>
                        
                    </tr>
                </thead>
                <tbody>
                    {
                        appointments?.map((appointment) => {

                            const fDate = new Date(filterDate).getDate()
                            const appDate = new Date(appointment.dateStart.split('T')[0]).getDate()

                            if (fDate && fDate !== appDate) return

                            const date = appointment.dateStart.split('T')[0]
                            const from = appointment.dateEnd.split('T')[1].split(':')[0] + ':' + appointment.dateEnd.split('T')[1].split(':')[1]
                            const to = appointment.dateEnd.split('T')[1].split(':')[0] + ':' + appointment.dateEnd.split('T')[1].split(':')[1]

                            return (
                                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" class="px-6 py-4 font-medium text-black-900 whitespace-nowrap dark:text-white">
                                        {`${appointment.user.first_name} ${appointment.user.last_name}`}
                                    </th>
                                    <td class="px-6 py-4">
                                        {date}
                                    </td>
                                    <td class="px-6 py-4">
                                        {from}
                                    </td>
                                    <td class="px-6 py-4">
                                        {to}
                                    </td>
                                    <td class="px-6 py-4">
                                        <Link to={`edit/${appointment._id}`} type="button" class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900">Edit</Link>
                                        <button type="button" onClick={() => deleteRecord(appointment._id)} class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</button>
                                    </td>
                                </tr>
                        )})
                    }          
                </tbody>
            </table>
    </div>
  );
}

export default Appointments;
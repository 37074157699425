import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import axios from "../../Config/axios"
import useAuth from "../../Hooks/useAuth"
import { deleteAndConfirmation } from "../../Utils/confirmation"

const Articles = () => {

    const [articles, setArticles] = useState({})

    const { auth } = useAuth()

    const fetchArticles = async () => {
        try {
            const response = await axios.get(`/v1/articles`, {
                headers: {
                    authorization: `Bearer ${auth.accessToken}`
                }
            })
            console.log(response.data);
            setArticles(response.data)
        } catch (error) {
            console.error(error);
        }
    }

    const deleteRecord = async (id) => {
        try {

            deleteAndConfirmation(`/v1/articles/${id}`, auth.accessToken, 1000, "The article has been deleted.")

            // navigate(0)

            // console.log(response);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchArticles()
    }, [])

    return (
        <div className='pl-20 pt-10 relative overflow-x-auto'>
            <Link to="/dashboard/articles/new">
                <Button variant="contained">Add new Article</Button>
            </Link>
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            Title
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Time Estimation
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Actions
                        </th>
                        
                    </tr>
                </thead>
                <tbody>
                    {
                        articles.articles?.map((article) => (
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 font-medium text-blue-900 whitespace-nowrap dark:text-white">
                                    <Link to={`${article._id}`}>
                                        {article.title}
                                    </Link>
                                </th>
                                <td class="px-6 py-4">
                                    {article.time_est}
                                </td>
                                <td class="px-6 py-4">
                                    <Link to={`edit/${article._id}`} type="button" class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900">Edit</Link>
                                    <button type="button" onClick={() => deleteRecord(article._id)} class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</button>
                                </td>
                            </tr>
                        ))
                    }          
                </tbody>
            </table>
        </div>
    )

}

export default Articles
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom'
import Login from '../Components/Auth/Login';
import Dashboard from '../Components/Dashboard';
import Appointments from '../Components/Appointments';
import CreateAppointment from '../Components/Appointments/Create';
import Register from '../Components/Auth/Register';
import Articles from '../Components/Articles';
import CreateArticle from '../Components/Articles/Create';
import Exercices from '../Components/Exercices';
import Meals from '../Components/Meals';
import Audios from '../Components/Audios';
import CreateMeal from '../Components/Meals/Create';
import CreateExercice from '../Components/Exercices/Create';
import RequireAuth from '../Components/Auth/RequireAuth';
import Messages from '../Components/Messages';
import ShowExercice from '../Components/Exercices/Show';
import EditExercice from '../Components/Exercices/Edit';
import ShowMeal from '../Components/Meals/Show';
import EditMeal from '../Components/Meals/Edit';
import ShowArticle from '../Components/Articles/Show';
import EditArticle from '../Components/Articles/Edit';
import CreateAudio from '../Components/Audios/Create';
import ShowAudio from '../Components/Audios/Show';
import Users from '../Components/Users';
import EditAppointment from '../Components/Appointments/Edit';

const RouterComponent = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Login/>} />
                    <Route element={<RequireAuth />} >
                        <Route path='dashboard' element={<Dashboard/>} >
                            <Route path='appointments' element={<Appointments/>} />
                            <Route path='appointments/new' element={<CreateAppointment/>} />
                            <Route path='appointments/edit/:id' element={<EditAppointment/>} />
                            <Route path='register' element={<Register/>} />
                            <Route path='articles' element={<Articles/>} />
                            <Route path='articles/new' element={<CreateArticle/>} />
                            <Route path='articles/:id' element={<ShowArticle/>} />
                            <Route path='articles/edit/:id' element={<EditArticle/>} />
                            <Route path='exercices' element={<Exercices/>} />
                            <Route path='exercices/new' element={<CreateExercice/>} />
                            <Route path='exercices/:id' element={<ShowExercice/>} />
                            <Route path='exercices/edit/:id' element={<EditExercice/>} />
                            <Route path='meals' element={<Meals/>} />
                            <Route path='meals/new' element={<CreateMeal/>} />
                            <Route path='meals/:id' element={<ShowMeal/>} />
                            <Route path='meals/edit/:id' element={<EditMeal/>} />
                            <Route path='audios' element={<Audios/>} />
                            <Route path='audios/new' element={<CreateAudio/>} />
                            <Route path='audios/:id' element={<ShowAudio/>} />
                            <Route path='messages' element={<Messages />} />
                            <Route path='users' element={<Users />} />
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default RouterComponent;
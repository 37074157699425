import Swal from "sweetalert2";
import axios from "../Config/axios";

export const deleteAndConfirmation = async (endPoint, accessToken, timeToReload, textAfterDelete) => {
    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(endPoint,{
                        headers: {
                            authorization: `Bearer ${accessToken}` 
                        }
                    })
                    console.log(response);

                    Swal.fire({
                        title: "Deleted!",
                        text: textAfterDelete,
                        icon: "success"
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, timeToReload); 
                } catch (error) {
                    console.error(error)
                }
            }
        });
}
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../Config/axios";
import { API_URL } from "../../Config";
import useAuth from "../../Hooks/useAuth"

const ShowArticle = () => {

    let { id } = useParams()

    const { auth } = useAuth()

    const [article, setArticle] = useState()

    const getArticle = async () => {
        try {
            const response = await axios.get(`/v1/articles/${id}`, {
                headers: {
                    authorization: `Bearer ${auth.accessToken}`
                }
            })
            console.log(response.data);
            setArticle(response.data.article)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getArticle()
    }, [])

    return (
        <div>

            <h3 class="text-3xl font-bold dark:text-white">{article?.title}</h3>

            <p>Read Time Estimated : {article?.time_est}</p>



            <img class="h-auto max-w-lg rounded-lg my-4" src={`${API_URL}/cdn/${article?.image}`} alt="image description" />

            <p className="mb-3">{article?.content}</p>


        </div>
    )
}

export default ShowArticle
import axios from "../../Config/axios"

import { useEffect, useState } from "react"

const CreateMessage = ({ recieverFullName, recieverUserId, senderFullName, senderUserId, auth }) => {

    const [message, setMessage] = useState('')
    const [messages, setMessages] = useState([])

    const getMessages = async () => {

        try {
            const response = await axios.get(`/v1/users/${recieverUserId}/messages/`, {
                headers: {
                    authorization: `Bearer ${auth.accessToken}`
                }
            })
    
            console.log("what", response);
    
            setMessages(response?.data)
        } catch (e) {
            console.error(e)
        }
    }

    const sendMessage = async () => {
        try {
            const response = await axios.post(`/v1/messages/`, 
                {
                    message,
                    user_id: recieverUserId,
                    message_from: 'admin'
                },
                {
                    headers: {
                        authorization: `Bearer ${auth.accessToken}`
                    }
                })

            setMessage('')
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getMessages()
    }, [recieverUserId])


    return (
        <div class="flex flex-row h-screen antialiased text-gray-800">
            <div class="flex flex-col h-full w-full bg-white px-4 py-6">
            <div class="flex flex-row items-center py-4 px-6 rounded-2xl shadow">
                <div class="flex items-center justify-center h-10 w-10 rounded-full bg-pink-500 text-pink-100">
                    {recieverFullName[0].toUpperCase()}
                </div>
                <div class="flex flex-col ml-3">
                <div class="font-semibold text-sm">{recieverFullName}</div>
                {/* <div class="text-xs text-gray-500">Active</div> */}
                </div>
                {/* <div class="ml-auto">
                    <ul class="flex flex-row items-center space-x-2">
                        <li>
                        <a href="#"
                            class="flex items-center justify-center bg-gray-100 hover:bg-gray-200 text-gray-400 h-10 w-10 rounded-full">
                            <span>
                            <svg class="w-5 h-5"
                                fill="currentColor"
                                stroke="none"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
                            </svg>
                            </span>
                        </a>
                        </li>
                        <li>
                        <a href="#"
                            class="flex items-center justify-center bg-gray-100 hover:bg-gray-200 text-gray-400 h-10 w-10 rounded-full">
                            <span>
                            <svg class="w-5 h-5"
                                fill="currentColor"
                                stroke="none"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"></path>
                            </svg>
                            </span>
                        </a>
                        </li>
                        <li>
                        <a href="#"
                            class="flex items-center justify-center bg-gray-100 hover:bg-gray-200 text-gray-400 h-10 w-10 rounded-full">
                            <span>
                            <svg class="w-5 h-5"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                            </svg>
                            </span>
                        </a>
                        </li>
                    </ul>
                </div> */}
            </div>
            <div class="h-full overflow-hidden py-4">
                <div class="h-full overflow-y-auto">
                    <div class="grid grid-cols-12 gap-y-2">
                        {
                            messages?.map((message) => message.messageFrom === 'admin' ? (
                                <div class="col-start-6 col-end-13 p-3 rounded-lg">
                                    <div class="flex items-center justify-start flex-row-reverse">
                                        <div
                                            class="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0"
                                        >
                                            AD
                                        </div>
                                        <div
                                            class="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl"
                                        >
                                        <div>{message.message}</div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div class="col-start-1 col-end-8 p-3 rounded-lg">
                                    <div class="flex flex-row items-center">
                                        <div
                                            class="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0"
                                        > 
                                            {recieverFullName[0].toUpperCase()}
                                        </div>
                                        <div
                                            class="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl"
                                        >
                                            <div>
                                                {message.message}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div class="col-start-1 col-end-8 p-3 rounded-lg">
                        
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row items-center">
                <div class="flex flex-row items-center w-full border rounded-3xl h-12 px-2">
                    <div class="w-full">
                        <input type="text"
                            onChange={e => setMessage(e.target.value)}
                            class="border border-transparent w-full focus:outline-none text-sm h-10 flex items-center"
                             placeholder="Type your message...."/>
                    </div>
                
                </div>
                <div class="ml-6">
                    <button onClick={() => sendMessage()} class="flex items-center justify-center h-10 w-10 rounded-full bg-gray-200 hover:bg-gray-300 text-indigo-800 text-white">
                        <svg class="w-5 h-5 transform rotate-90 -mr-px"
                            fill="none"
                            stroke="blue"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
                        </svg>
                    </button>
                </div>
            </div>
            </div>
        </div>
    )

}

export default CreateMessage
import { createContext, useEffect, useState } from "react";



const AuthContext = createContext()


export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({accessToken: localStorage.getItem('accessToken') } || {})


    const setToken = (user) => {
        localStorage.setItem('accessToken', user.accessToken)
        setAuth(user)
    }

    const clearToken = () => {
        localStorage.removeItem('accessToken')
        setAuth({})
    }

    return (
        <AuthContext.Provider value={{auth, setToken, clearToken}}>
            {children}
        </AuthContext.Provider>
    )
}


export default AuthContext
import { useContext, useRef, useState } from "react"
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"

import axios from "../../Config/axios"
import useAuth from "../../Hooks/useAuth"

const Login = () => {

    const { setToken, auth } = useAuth()

    const userRef = useRef()

    const navigate = useNavigate()
    const location = useLocation()
    // const from = location.state?.from?.pathname || "/dashboard"
    const from = "/dashboard"

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const login = async (e) => {
        
        e.preventDefault();



        try {
            const response = await axios.post(
                '/v1/login',
                JSON.stringify({username, password}),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })

            const accessToken = response?.data?.token

            const { first_name, last_name, id } = response?.data?.user
            const roles = 'admin' //TODO

            // setAuth({
            //     id,
            //     username,
            //     roles,
            //     first_name,
            //     last_name,
            //     accessToken
            // })

            const jwt = {
                accessToken: accessToken
            }

            setToken(jwt)
            
            navigate(from, {replace: true})

            console.log(JSON.stringify(response?.data));

        } catch (error) {
            console.error(error)
        }

    }


    return (

        auth?.accessToken
            ? <Navigate to="/dashboard" />
            : <div className="flex justify-center	pt-32">
            <div className="flex flex-col w-full max-w-md px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
                <div className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
                    Login To Your Account
                </div>
                <div className="mt-8">
                    <form action="#" autoComplete="off" onSubmit={login}>
                        <div className="flex flex-col mb-2">
                            <div className="flex relative ">
                                
                                <input type="text" id="sign-in-username" onChange={e => setUsername(e.target.value)} className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-sky-600 focus:border-transparent" placeholder="Your username"/>
                            </div>
                        </div>
                        <div className="flex flex-col mb-6">
                            <div className="flex relative ">
                        
                            <input type="password" id="sign-in-password" onChange={e => setPassword(e.target.value)} className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-sky-600 focus:border-transparent" placeholder="Your password"/>
                            </div>
                        </div>
                        
                        <div className="flex w-full">
                            <button type="submit" className="py-2 px-4  bg-sky-600 hover:bg-sky-700 focus:ring-sky-500 focus:ring-offset-sky-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default Login
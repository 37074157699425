import { useState } from "react"
import axios from "../../Config/axios"
import { useNavigate } from "react-router-dom"
import useAuth from "../../Hooks/useAuth"


const CreateArticle = () => {

    const navigate = useNavigate()

    const { auth } = useAuth()

    const [title, setTitle] = useState('')
    const [timeEst, setTimeEst] = useState('')
    const [content, setContent] = useState('')
    const [image, setImage] = useState('')

    const handleSubmit = async (e) => {

        e.preventDefault()

        const formData = new FormData();
        formData.append('title', title)
        formData.append('time_est', timeEst)
        formData.append('content', content)
        formData.append('image', image)

        try {
            const response = await axios.post(
                '/v1/articles',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        authorization: `Bearer ${auth.accessToken}`
                    }
                })

            console.log(response.data);

            navigate('/dashboard/articles')


        } catch (error) {
            console.error(error)
        }
        // await response.json() 
    }



    return (
        <form class="max-w-lg mx-auto mt-20" onSubmit={handleSubmit}>

        
            <div class="relative z-0 w-full mb-5 group">
                <input type="text" onChange={e => setTitle(e.target.value)} name="title" id="title" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <label for="title" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Title</label>
            </div>

            <div class="relative z-0 w-full mb-5 group">
                <input type="text" onChange={e => setTimeEst(e.target.value)} name="title" id="title" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <label for="time_est" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Read time estimation
                </label>
            </div>

            <div class="relative z-0 w-full mb-5 group">
                <label for="content" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Content
                </label>
                <textarea id="notes" onChange={e => setContent(e.target.value)} rows="4" cols="50" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Article Content..."></textarea>
            </div>

            <div class="relative z-0 w-full mb-5 group">
                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="user_avatar">Image</label>
                <input onChange={e => setImage(e.target.files[0])} class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="image" type="file"></input>
            </div>

            <button type="submit" class="mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
        </form>
    )
}

export default CreateArticle
import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import axios from "../../Config/axios"
import { useEffect, useState } from "react"
import useAuth from "../../Hooks/useAuth"
import { deleteAndConfirmation } from "../../Utils/confirmation"

const Users = () => {

    const { auth } = useAuth()

    const [users, setUsers] = useState([]);

    const sexObject = {
        M: 'Male',
        F: 'Female'
    }

    const fetchMeals = async () => {
        try {
            const response = await axios.get('/v1/users', {
                headers: {
                    authorization: `Bearer ${auth.accessToken}`
                }
            })
            console.log(response?.data);
            setUsers(response?.data?.user)
        } catch(error) {
            console.error(error);
        }
        
        // setAppointments(data)
    }


    const deleteRecord = async (id) => {
        try {
            deleteAndConfirmation()                                                                                            
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchMeals()
    }, [])

    return (
        <div className='pl-20 pt-10 relative overflow-x-auto'>
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            Username
                        </th>
                        <th scope="col" class="px-6 py-3">
                            First Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Last Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Role
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Phone
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Sex
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Height
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Weight
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Date of Birth
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>

                    {

                        users?.map((user) => (
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 font-medium text-blue-900 whitespace-nowrap dark:text-white">
                                    <Link to={`${user?._id}`}>
                                        {user?.username}
                                    </Link>
                                </th>
                                <td class="px-6 py-4">
                                    {user?.first_name}
                                </td>
                                <td class="px-6 py-4">
                                    {user?.last_name}
                                </td>
                                <td class="px-6 py-4">
                                    {user?.role}
                                </td>
                                <td class="px-6 py-4">
                                    {user?.phone}
                                </td>
                                <td class="px-6 py-4">
                                    {user?.sex && sexObject[user?.sex]}
                                </td>
                                <td class="px-6 py-4">
                                    {user?.height}
                                </td>
                                <td class="px-6 py-4">
                                    {user?.weight}
                                </td>
                                <td class="px-6 py-4">
                                    {user?.date_of_birth?.split('T')[0]}
                                </td>
                                <td class="px-6 py-4">
                                    <Link to={`edit/${user?._id}`} type="button" class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900">Edit</Link>
                                    <button type="button" onClick={() => deleteRecord(user?._id) } class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</button>
                                </td>
                            </tr>
                        ))
                        
                    }
                </tbody>
            </table>
        </div>
    )

}

export default Users
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../Config/axios";
import { API_URL } from "../../Config";
import useAuth from "../../Hooks/useAuth";

const ShowAudio = () => {

    let { id } = useParams()

    const { auth } = useAuth()

    const [audio, setAudio] = useState()

    const getAudio = async () => {
        try {
            const response = await axios.get(`/v1/audios/${id}`, {
                headers: {
                    authorization: `Bearer ${auth.accessToken}`
                }
            })
            console.log(response.data);
            setAudio(response.data.audio)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getAudio()
    }, [])

    return (
        <div>

            <h3 class="text-3xl font-bold dark:text-white my-4">{audio?.title}</h3>

            <audio controls autoplay className="my-4" name="media">
                <source src={`${API_URL}/cdn/${audio?.audio}`} type="audio/mpeg"/>
            </audio>


        </div>
    )
}

export default ShowAudio
import './App.css';
import RouterComponent from './Router';

function App() {
  return (
    <div>
      <RouterComponent></RouterComponent>
    </div>
  );
}

export default App;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../Config/axios";
import { API_URL } from "../../Config";
import useAuth from "../../Hooks/useAuth";

const ShowMeal = () => {

    const { id } = useParams()

    const { auth } = useAuth()

    const [meal, setMeal] = useState()

    const getMeal = async () => {
        try {
            const response = await axios.get(`/v1/meals/${id}`, {
                headers: {
                    authorization: `Bearer ${auth.accessToken}`
                }
            })
            console.log(response.data);
            setMeal(response.data.meal)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getMeal()
    }, [])

    return (
        <div>

            <h3 class="text-3xl font-bold dark:text-white">{meal?.title}</h3>

            <p>Calories : {meal?.calories}</p>
            <p>Read Time Estimated : {meal?.time_est}</p>



            <img class="h-auto max-w-lg rounded-lg my-4" src={`${API_URL}/cdn/${meal?.image}`} alt="image description" />

            <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Steps</h2>
            <ul class="max-w-md space-y-1 text-black-500 list-disc list-inside dark:text-black-400">
                {
                    meal?.steps?.map((step) => (
                        <li>
                            {step}
                        </li>
                    ))
                }
            </ul>
            
            <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Notes</h2>
            <ul class="max-w-md space-y-1 text-black-500 list-disc list-inside dark:text-black-400">
                {
                    meal?.notes?.map((note) => (
                        <li>
                            {note}
                        </li>
                    ))
                }
            </ul>


        </div>
    )
}

export default ShowMeal
import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { API_URL } from "../../Config"
import axios from "../../Config/axios"
import useAuth from "../../Hooks/useAuth"
import { deleteAndConfirmation } from "../../Utils/confirmation"

const Audios = () => {

    const [audios, setAudios] = useState([])

    const { auth } = useAuth()

    const fetchAudios = async () => {

        try {
            const response = await axios.get(`/v1/audios`, {
                headers: {
                    authorization: `Bearer ${auth.accessToken}`
                }
            })
            console.log(response?.data);
            setAudios(response?.data?.audios)
        } catch (error) {
            console.error(error);
        }

    }

    const deleteRecord = async (id) => {
        try {
            deleteAndConfirmation(`/v1/audios/${id}`, auth.accessToken, 1000, "The audio has been deleted!")

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchAudios()
    }, [])

    return (
        <div className='pl-20 pt-10 relative overflow-x-auto'>
            <Link to="/dashboard/audios/new">
                <Button variant="contained">Add new Audio</Button>
            </Link>
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            Title
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Actions
                        </th>
                        
                    </tr>
                </thead>
                <tbody>
                    {
                        audios?.map((audio) => (
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 font-medium text-blue-900 whitespace-nowrap dark:text-white">
                                    <Link to={`${audio._id}`}>
                                        {audio.title}
                                    </Link>
                                </th>
                                <td class="px-6 py-4">
                                    {/* <Link to={`edit/${audio._id}`} type="button" class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900">Edit</Link> */}
                                    <button type="button" onClick={() => deleteRecord(audio._id)} class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</button>
                                </td>
                            </tr>
                        ))
                    }          
                </tbody>
            </table>
        </div>
    )

}

export default Audios
import { useEffect, useState } from "react"
import { Link, Outlet } from "react-router-dom"
import axios from "../../Config/axios"
import CreateMessage from "./Create"
import useAuth from "../../Hooks/useAuth"

const Messages = () => {

    const [users, setUsers] = useState([])

    const { auth } = useAuth()

    console.log('====================================');
    console.log(auth);
    console.log('====================================');

    const [currentUser, setCurrentUser] = useState(0)

    const getUsers = async () => {
        try {
            const response = await axios.get('/v1/users/', {
                headers: {
                    Authorization: `Bearer ${auth.accessToken}`
                }
            })

            console.log(response?.data);

            setUsers(response.data.user)
        } catch (error) {
            console.error(error);
        }
    }

    const seleteUser = (i) => {
        setCurrentUser(i)
    }

    useEffect(() => {
        getUsers()
    }, [currentUser])

    return (

        <>
            <div class="h-full py-4 px-4 overflow-y-auto ">
                <ul class="space-y-3 font-medium">
                    <li>
                        {
                            users?.map((user, i) => (
                                <>
                                    <div poiter onClick={() => seleteUser(i)} class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                        <span class="ms-3">{user?.first_name} {user?.last_name} ({user?.username})</span>
                                        <span class="ml-3 flex items-center self-end justify-center h-5 w-5 bg-red-500 text-white text-xs rounded-full"></span>
                                    </div>
                                </>
                            ))
                        }
                    </li>          
                </ul>
            </div>
            
            <CreateMessage 
                recieverFullName={`${users[currentUser]?.first_name} ${users[currentUser]?.last_name} (${users[currentUser]?.username})`}
                recieverUserId={users[currentUser]?._id}
                senderFullName={`${auth?.first_name} ${auth?.last_name}`}
                senderUserId={auth?.id}
                auth={auth}
            />
        </>
    )
}

export default Messages
import { Outlet } from "react-router-dom"
import Sidebar from "../Layouts/Sidebar"


const Dashboard = () => {
    return (
        <div style={{display: 'flex'}}>
            <Sidebar />
            <Outlet />
        </div>
    )
}

export default Dashboard
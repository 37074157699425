import { useEffect, useState } from 'react';
import axios from '../../Config/axios';
import useAuth from '../../Hooks/useAuth';
import { useParams } from 'react-router-dom';

const EditAppointment = () => {

    const { auth } = useAuth()
    const [users, setUsers] = useState([])

    const { id } = useParams()

    const [userId, setUserId] = useState('')
    const [date, setDate] = useState('')
    const [timeStart, setTimeStart] = useState('')
    const [timeEnd, setTimeEnd] = useState('')

    const fetchUsers = async () => {
        // const response = await fetch(`${API_URL}/v1/users/`)

        const response = await axios.get('/v1/users/', {
            headers: {
                authorization: `Bearer ${auth.accessToken}`
            }
        })
        console.log(response.data);
        setUsers(response.data)
    }

    const getAppointment = async (id) => {
        try {
            const response = await axios.get(`/v1/appointments/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${auth.accessToken}`
                }
            })

            const appointment = response.data.appointment

            console.log(appointment)

            const date = appointment.dateStart.split('T')[0]
            const from = appointment.dateEnd.split('T')[1].split(':')[0] + ':' + appointment.dateEnd.split('T')[1].split(':')[1]
            const to = appointment.dateEnd.split('T')[1].split(':')[0] + ':' + appointment.dateEnd.split('T')[1].split(':')[1]

            setUserId(appointment.user)
            setDate(date)
            setTimeEnd(from)
            setTimeStart(to)
        } catch (e) {
            console.error(e)
        }

    }

    const updateAppointment = async (event) => {
        event.preventDefault();
        // console.log(userId, date, timeStart, timeEnd)

        const dateStart = `${date}T${timeStart}:00`
        const dateEnd = `${date}T${timeEnd}:00`


        try {
            const response = await axios.put(`/v1/appointments/${id}`,
            {
                user_id: userId,
                dateStart,
                dateEnd
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${auth.accessToken}`
                }
            })
        } catch (e) {
            console.error(e)
        }
        
    }

    useEffect(() => {
        // setAppointments(fetchAppointments())
        getAppointment(id)
        fetchUsers()
    }, [])

    return (
        
        <form class="max-w-md mx-auto mt-20" onSubmit={e => updateAppointment(e)}>
          <div class="relative z-0 w-full mb-5 group">
          <label for="users" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label>
                <select onChange={e => setUserId(e.target.value)} id="users" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                   

                <option>Choose a user</option>

                    {users.user && users.user.map((row) => {

                        if (row._id == userId) {
                            return <option value={row._id} selected>{row.first_name} {row.last_name}</option>
                        } else {
                            return <option value={row._id}>{row.first_name} {row.last_name}</option>
                        }
                        
                    })
                   } 
        
                </select>
          </div>
          <div class="relative z-0 w-full mb-5 group">
              <input onChange={e => setDate(e.target.value)}
                value={date}
                type="date" name="dateTo" id="floating_repeat_password" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
              <label for="floating_repeat_password" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Date</label>
          </div>

          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-5 group">
                <input onChange={e => setTimeStart(e.target.value)}
                    value={timeStart}
                    type="time" name="timeFrom" id="floating_repeat_password" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <label for="floating_repeat_password" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Time From</label>
            </div>
            <div class="relative z-0 w-full mb-5 group">
                <input onChange={e => setTimeEnd(e.target.value)}
                    value={timeEnd}
                    type="time" name="timeTo" id="floating_repeat_password" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <label for="floating_repeat_password" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Time To</label>
            </div>
          </div>
    
          <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
        </form>
    )
}

export default EditAppointment;